export const PDF_PATHS = {
  DOCUMENT_3_REASONS: {
    en: "/pdf/3_Reasons_Ootbi_is_Best_Storage_for_Veeam_en.pdf",
    de: "/pdf/3_Reasons_Ootbi_is_Best_Storage_for_Veeam_en.pdf",
    fr: "/pdf/3_Reasons_Ootbi_is_Best_Storage_for_Veeam_en.pdf",
    es: "/pdf/3_Reasons_Ootbi_is_Best_Storage_for_Veeam_en.pdf",
    'es-419': "/pdf/3_Reasons_Ootbi_is_Best_Storage_for_Veeam_en.pdf", // es-latam
    it: "/pdf/3_Reasons_Ootbi_is_Best_Storage_for_Veeam_en.pdf",
    pt: "/pdf/3_Reasons_Ootbi_is_Best_Storage_for_Veeam_en.pdf",
  },
  OF_DATA_SHEET: {
    en: "/pdf/EN_Datasheet_Ootbi.pdf",
    de: "/pdf/DE_Datasheet_Ootbi.pdf",
    fr: "/pdf/FR_Datasheet_Ootbi.pdf",
    es: "/pdf/ES_Datasheet_Ootbi.pdf",
    it: undefined,
    pt: "/pdf/PT-BR_Datasheet_Ootbi.pdf",
  },
  ZERO_TRUST: {
    en: "/pdf/Zero_Trust_and_Enterprise_Data_Backup.pdf",
    de: "/pdf/Zero_Trust_and_Enterprise_Data_Backup_de.pdf",
    fr: "/pdf/Zero_Trust_and_Enterprise_Data_Backup_fr.pdf",
    es: "/pdf/Zero_Trust_and_Enterprise_Data_Backup_es.pdf",
    it: '/pdf/Zero_Trust_and_Enterprise_Data_Backup_it.pdf',
    pt: "/pdf/Zero_Trust_and_Enterprise_Data_Backup_pt-br.pdf",
  },
  NIS2_DIRECTIVE_WHITE_PAPER: {
    en: "/pdf/nis2_directive_whitepaper_en.pdf",
    de: "/pdf/nis2_directive_whitepaper_de.pdf",
    fr: "/pdf/nis2_directive_whitepaper_fr.pdf",
    es: "/pdf/nis2_directive_whitepaper_es.pdf",
    it: "/pdf/nis2_directive_whitepaper_it.pdf",
  },
} as const;
